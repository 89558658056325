import React, { useEffect, useState } from 'react'
import Layout from 'src/layouts/BaseLayout'

import IntercomLogo from 'assets/images/temps/intercom-logo.png'
import useIntercomChat from '../hooks/useIntercomChat'

import content from './../../static/atendimento-chat/content.json'

const DEFAULT_INDEX = [ 'divulgacao', 'portabilidade' ]

const contentMetas = {
  slug: '/atendimento-chat/',
  metaTitle: 'Atendimento Chat',
  hideFooter: true,
  noIndexOption: true,
  hideOpenAccountBottomForm: true,
}

function AtendimentoChat () {
  function redirectAtendimentoChatToOuvidoria () {
    const urlCameFromBacen = window.location.search === '?tipoAssunto=migracaobacen&assunto=atendimento-ouvidoria'

    function isNotBusinessDay (date) {
      const day = date.getDay()
      const notBusinessDay = (
        day === 0 ||
        day === 6
      )

      return notBusinessDay
    }

    function isNotBusinessHour (date) {
      const hourOnSaoPauloTimezone = date.getUTCHours() - 3
      const minutes = date.getMinutes()
      const notBusinessHour = (
        (hourOnSaoPauloTimezone < 9) ||
        (hourOnSaoPauloTimezone === 18 && minutes > 0) ||
        (hourOnSaoPauloTimezone >= 19)
      )

      return notBusinessHour
    }

    if (urlCameFromBacen) {
      const date = new Date()

      if (
        isNotBusinessHour(date) ||
        isNotBusinessDay(date)
      ) {
        window.location.href = 'https://inter.co/ouvidoria/?tipoAssunto=migracaobacen&assunto=atendimento-ouvidoria#form-ouvidoria'
      }
    }
  }

  let chatDescription
  let customChatMessage
  let tipoAssunto = DEFAULT_INDEX[0]
  let assunto = DEFAULT_INDEX[1]

  const [ domReady, setDomReady ] = useState(false)

  if (domReady) {
    const url = new URL(window.location.href)
    tipoAssunto = url.searchParams.get('tipoAssunto')
    assunto = url.searchParams.get('assunto')

    if (!Object.keys(content).includes(tipoAssunto) || !Object.keys(content[tipoAssunto]).includes(assunto)) {
      tipoAssunto = DEFAULT_INDEX[0]
      assunto = DEFAULT_INDEX[1]
    }
    customChatMessage = content[tipoAssunto][assunto].p2
    redirectAtendimentoChatToOuvidoria()
  }

  useEffect(() => setDomReady(true), [])
  useIntercomChat(false, customChatMessage)

  if (tipoAssunto === 'solicitacao-contato' || tipoAssunto === 'encerramento-de-conta' || tipoAssunto === 'enderecoCVM') {
    chatDescription = <p>Clique no <img src={IntercomLogo} alt='Logo da Intercom' /> para conversar com a gente.</p>
  } else if (tipoAssunto === 'contratacao') {
    chatDescription = <p>Clique em <img src={IntercomLogo} alt='Logo da Intercom' /> para dar andamento ao seu contato.</p>
  } else {
    chatDescription = <p>{content[tipoAssunto][assunto].messageChat} <img src={IntercomLogo} alt='Logo da Intercom' /> {content[tipoAssunto][assunto].messageChatSuffix || '.'}</p>
  }

  return (
    <Layout pageContext={contentMetas}>
      {content && domReady && (
        <section className='py-5'>
          <div className='container pt-xl-5'>
            <div className='row align-items-center justify-content-center'>
              <div className='col-12 col-md-10 col-lg-7 col-xl-6'>
                <h3 className='fs-lg-70 fs-50 text-orange--base'>Olá :)</h3>
                <p className='lh-22' dangerouslySetInnerHTML={{ __html: content[tipoAssunto][assunto].p1 }} />
                {chatDescription}
                <p>{content[tipoAssunto][assunto].messagePrefix} <br /><span className='text-orange--base fs-20 lh-22'>"{content[tipoAssunto][assunto].p2}"</span></p>
                <p>{content[tipoAssunto][assunto].p3}</p>
              </div>
            </div>
          </div>
        </section>
      )}
    </Layout>
  )
}

export default AtendimentoChat
